a {
  text-decoration: none;
}

a[href^="mailto"]:after {
  content: " " url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAHCAYAAADam2dgAAAA3ElEQVQYlU3OO07DQBhF4YtZAgXLoGABlDSBxgJEYmzPw78dTaIBO/O0LQ0FElBTIBr6bDJUSDn1VxyQikrQ+CHacZIU55qmuaQ4V02YauHfC2E0RONeuRz83X5/iqNSOmREdl5K94ZKBFuqcEZkB+Bw8o+IjJcynhe1d6i60QIAb90F0fACANTZQUp/CQBL4R1KOZmUUiZb/7Diu+cV95+FcLpq3H1KKXtkwaKm0fN1uGVdvD5+YixcscYsnoQP4Gv3pdTwq1S/0NrketPnm67Pt9vdDXXmu2zczx9FfFXtJM0fywAAAABJRU5ErkJggg==");
}

a[href^="tel"]:after {
  content: " " url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAnElEQVQYlXXOsQ3CQAyFYSNEBwI2YBEqGnZhDhagxO90NGlOiMYOKSKRhvL2gBVoMEVyCUiHJVf+JP9ERAQIM4t53ywoN86VO0DfzGKAllnErBdmsRbJ+R86JGRmoywCqk1Czt1nWdRCfXRNxxjjhIjIe117X616FEIzBeTVdT0BPQ2d5baHRaHLdPheQK4/b0OwMbPuB6C3EOr5B2wmeXFVLlSdAAAAAElFTkSuQmCC");
}

footer {
  font-size: smaller;
}

footer a {
  font-weight: normal;
}

h1 {
  color: #0c234b;
}

#info-button, #news {
  margin-bottom: 20px;
}

#subheading {
  color: #0c234b;
  margin-top: -25px;
  margin-bottom: 30px;
}

#subheading-text {
  margin-top: 20px;
  font-size: 18px;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px #0000001f, 0 4px 8px #0000000f;
}

.card-deck {
  margin-bottom: 30px;
}

.card-header {
  color: #fff;
  font-size: 30px;
}

.info-text {
  font-size: 20px;
}

.list-group {
  font-size: 16px;
}

.logo {
  max-height: auto;
  float: right;
  max-width: 15%;
  margin: 10px;
}

.navbar {
  margin-top: 20px;
  margin-bottom: 15px;
}

.text-center {
  justify-content: center;
  display: flex;
}
/*# sourceMappingURL=index.74c68530.css.map */
